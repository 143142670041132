import vuePDF from "vue-pdf";

export default {
  name: "ReportTrialBalance",
  data() {
    return {
      dataForm: {
        cabang: "",
        akun: "",
        kelompok: "",
        eksport: "",
        fileType: "pdf",
        officeIdList: [],
      },
      pdf: {
        src: null,
        pdfToDownload: null,
        numPages: 0,
      },
      isLoading: false,
      disabled: [],
      property: {
        modal: {
          showModalPreviewPDFTrialBalance: false,
        },
      },
      options: {
        kelompok: [],
        akun: [],
        eksport: [],
        officeIdList: [],
        officeListDataSoure: [],
      },
      table: {
        data: [],
      },
    };
  },
  methods: {
    selectAllOfficeList() {
      this.dataForm.officeIdList = this.options.officeIdList;
      this.options.officeIdList = [];
    },
    addOfficeList(event) {
      var unselectedOffice = this.options.officeIdList.filter(
        (index) => index.value !== event.value
      );
      this.options.officeIdList = unselectedOffice;
      this.disabled = [];
      this.dataForm.officeIdList.map((index) => {
        this.disabled.push(index.text);
      });
    },
    deleteOfficeList(event) {
      this.options.officeIdList.push(event);
      this.disabled = [];
    },
    async helperGetOfficeDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          params: {
            officeName: "",
            page: 0,
          },
          url: "office",
        })
        .then((response) => {
          this.options.officeIdList = [
            { text: "-- Semua Cabang --", value: "" },
            { text: "-- Consolidasi --", value: "" },
          ];
          this.options.officeListDataSoure = [
            { text: "-- Semua Cabang --", value: "" },
            { text: "-- Consolidasi --", value: "" },
          ];
          response.data.data.content.map((index) => {
            const value = index.officeId;
            const text = index.officeName;
            this.options.officeIdList.push({
              value,
              text,
            });
            this.options.officeListDataSoure.push({
              value,
              text,
            });
          });
        });
    },

    filterOfficeIdList(text) {
      this.options.officeIdList = this.options.officeListDataSoure.filter(
        (index) => {
          return (
            index.text
              .toString()
              .toLowerCase()
              .indexOf(text.toLowerCase()) >= 0
          );
        }
      );
    },

    appendDefaultOptionSelect() {
      const stringConditionDefaultOption = { value: "", text: "-- Pilih --" };
      this.options.kelompok = [stringConditionDefaultOption];
      this.options.akun = [stringConditionDefaultOption];
      this.options.akun.push(
        {
          value: "ALL",
          text: "Semua",
        },
        {
          value: "VALUE",
          text: "Yang Bernilai",
        }
      );
      this.options.kelompok.push(
        {
          value: "NERACA",
          text: "Neraca",
        },
        {
          value: "LABA_RUGI",
          text: "Laba Rugi",
        }
      );
      this.options.eksport.push(
        {
          value: "pdf",
          text: "Pdf",
        },
        {
          value: "xls",
          text: "Excel",
        }
      );
    },
    appendDefaultInput() {
      this.dataForm.eksport = "1";
    },
    printTrialBalance() {
      var fileLink = document.createElement("a");
      fileLink.href = this.pdf.pdfToDownload;
      fileLink.setAttribute("download", "trial_balance.pdf");
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    closeModalPreviewPDFTrialBalance() {
      this.pdf.src = null;
      this.pdf.pdfToDownload = null;
      this.pdf.numPages = 0;
      this.property.modal.showModalPreviewPDFTrialBalance = false;
    },
    async processReportTrialBalance() {
      const finalOfficeListData = [];
      this.dataForm.officeIdList.map((index) => {
        if (index.value) {
          finalOfficeListData.push(index.value);
        }
      });
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Proses Data ?",
          type: "is-success",
          onConfirm: async () => {
            this.pdf.src = null;
            this.pdf.numPages = 0;
            this.pdf.pdfToDownload = null;
            this.isLoading = true;
            try {
              const payload = {
                group: this.dataForm.kelompok,
                account: this.dataForm.akun,
                fileType: "pdf",
                officeIdList: finalOfficeListData.toString(),
              };
              const response = await this.$store.dispatch({
                type: "GET_SAVE_RESPONSE_DATA",
                endPoint: "report",
                reqUrl: "report/download-trial-balance",
                payload: payload,
              });
              console.log(response);
              const blob = new Blob([response.data]);
              const objectUrl = URL.createObjectURL(blob);
              var load = vuePDF.createLoadingTask(objectUrl);
              this.pdf.pdfToDownload = objectUrl;
              this.pdf.src = load;
              this.pdf.src.promise.then((pdf) => {
                this.pdf.numPages = pdf.numPages;
              });
              this.property.modal.showModalPreviewPDFTrialBalance = true;
            } catch (error) {
              this.pdfsrc = null;
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? `${error.response.statusText}`
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    async onDownload() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const finalOfficeListData = [];
      this.dataForm.officeIdList.map((index) => {
        if (index.value) {
          finalOfficeListData.push(index.value);
        }
      });
      const payload = {
        group: this.dataForm.kelompok,
        account: this.dataForm.akun,
        fileType: this.dataForm.fileType,
        officeIdList: finalOfficeListData.toString(),
      };
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/download-trial-balance",
          payload: payload,
        });
        var a = document.createElement("a"),
          url = URL.createObjectURL(response.data);
        a.href = url;
        a.download = `Report_Trial_Balance_Kel_${this.dataForm.kelompok}.${this.dataForm.fileType}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },
    async onDownloadZip() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const finalOfficeListData = [];
      this.dataForm.officeIdList.map((index) => {
        if (index.value) {
          finalOfficeListData.push(index.value);
        }
      });
      const payload = {
        group: this.dataForm.kelompok,
        account: this.dataForm.akun,
        fileType: this.dataForm.fileType,
        officeIdList: finalOfficeListData.toString(),
      };
      this.isLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/download-trial-balance/zip",
          payload: payload,
        });
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Report_Trial_Balance.${"zip"}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.helperGetOfficeDataForReference();
    this.appendDefaultOptionSelect();
    this.appendDefaultDateNow();
  },
};
